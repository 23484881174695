<template>
  <el-row>
    <el-col :ld="12" :md="12" :sm="18" :xs="24">
      <el-form
        ref="ruleForm"
        :model="ruleForm"
        status-icon
        :rules="rules"
        label-width="85px"
        class="demo-ruleForm"
      >
        <el-form-item label="当前密码" prop="nowPass">
          <el-input v-model.number="ruleForm.nowPass"></el-input>
        </el-form-item>

        <el-form-item label="新手机号码" prop="newPhone">
          <el-input v-model.trim="ruleForm.newPhone"></el-input>
        </el-form-item>

        <el-form-item class="wrap-btn">
          <el-button @click="submitForm('ruleForm')">确认修改</el-button>
          <el-button @click="() => this.$router.push('/Account/index')" type="primary"
            >返回</el-button
          >
        </el-form-item>
      </el-form>
    </el-col>
  </el-row>
</template>

<script>
import mgr from '@/services/security.js'
import { ElMessage } from "element-plus";
import AES from "@/components/AES.js";
export default {
  data() {
    const checkNowPass = (rule, value, callback) => {
      if (value === '') {
        callback(new Error('请输入当前密码'))
      } else {
        callback()
      }
    }
    const checkPhone = (rule, value, callback) => {
      if (value === '') {
        callback(new Error('请输入新手机号'))
      } else if (value == 'oldPhoneNum') {
        callback(new Error("新手机号不能和旧手机号相同"))
      } else {
        callback()
      }
    }
    return {
      ruleForm: {
        newPhone: '',
        nowPass: '',
      },
      rules: {
        newPhone: [{ validator: checkPhone, trigger: 'blur' }],
        nowPass: [{ validator: checkNowPass, trigger: 'blur' }],
      },
    }
  },
  methods: {
    submitForm(formName) {
      let keyCode = 'abcdsxyzhkj12345';
      let nowPass = AES.encrypt(this.ruleForm.nowPass);
      nowPass = nowPass.replaceAll('+', '%2B');
      this.$refs[formName].validate(async (valid) => {
        if (valid) {
        let me = this;
        await this.$ajax
        .post(me.$appConfig.apiUrls.userApi 
        + "/api/User/UpdatePhone?password=" + nowPass
        + '&phone=' + me.ruleForm.newPhone
        + '&keyCode=' + keyCode)
        .then(function (response) {
          if (response.data.IsSuccess) {
            me.$store.dispatch('getUserInfo');
            ElMessage({
              type: "success",
              message: "修改成功",
            });
          } else {
            ElMessage({
              type: "warning",
              message: response.data.Data == null ? '修改失败！' : response.data.Data,
            });
          }
        });
        } else {
          console.log('error submit!!')
          return false
        }
      })
    },
    async getUserName() {
      const user = await mgr.getUser();
      console.log(user);
      // this.userName = user.profile.name;
    },

  },
  beforeRouteLeave (to, from, next) {
    this.$refs['ruleForm'].resetFields()
    next();
  }
}
</script>
<style scoped>
/* .el-row {
  padding: 15px;
} */
.el-col {
  background-color: #fff;
  padding: 10px;
}
.demo-ruleForm .wrap-btn {
  text-align: center;
}
.demo-ruleForm :deep(.el-form-item__label) {
  font-weight: bold;
  color: #000000;
}
.demo-ruleForm :deep(.el-form-item) {
  margin-bottom: 20px;
}
</style>